import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ContentRoute, LayoutSplashScreen } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import { MyPage } from "./pages/MyPage";

const Categories = lazy(() => import("./modules/Categories/Categories"));

const DailyChallenges = lazy(() =>
  import("./modules/DailyChallenges/Dailychallenges")
);

const SpecialBonus = lazy(() => import("./modules/SpecialBonus/Specialbonus"));

const UserManage = lazy(() => import("./modules/UserManage/Usermanage"));

const Notification = lazy(() => import("./modules/Notification/Notifications"));

const Stages = lazy(() => import("./modules/Stages/Stages"));

const AdminUser = lazy(() => import("./modules/AdminUser/Adminuser"));
const Faqs = lazy(() => import("./modules/Faqs/Faq"));

const AOK = lazy(() => import("./modules/AOK/Aok"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/aok" component={AOK} />
        <Route path="/categories" component={Categories} />
        <Route path="/Dailychallenges" component={DailyChallenges} />
        <Route path="/Specialbonus" component={SpecialBonus} />
        <Route path="/Usermanage" component={UserManage} />
        <Route path="/Notification" component={Notification} />
        <Route path="/Stages" component={Stages} />
        <Route path="/Adminuser" component={AdminUser} />
        <Route path="/faqs" component={Faqs} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
