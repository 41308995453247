/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import SVG from "react-inlinesvg";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive, toAbsoluteUrl } from "../../../../_helpers";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul
        className={`menu-nav data-auto-scroll="true" ${layoutProps.ulClasses}`}
      >
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-arrange.svg"
                )}
              />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/aok", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/aok">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Heart.svg")} />
            </span>
            <span className="menu-text">Act of Kindness</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/categories", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/categories">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                )}
              />
            </span>
            <span className="menu-text">Categories</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}

        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/faqs", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/faqs">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Layout/Layout-4-blocks.svg"
                )}
              />
            </span>
            <span className="menu-text">Faqs</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(
            "/dailychallenges",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dailychallenges">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")}
              />
            </span>
            <span className="menu-text">Challenges</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/specialbonus", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/specialbonus">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Gift.svg")} />
            </span>
            <span className="menu-text">Special Bounses</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/Notification", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/Notification">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Star.svg")} />
            </span>
            <span className="menu-text">Notifications</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/stages", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/stages">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Design/Component.svg")}
              />
            </span>
            <span className="menu-text">Stages</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/usermanage", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/usermanage">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">User Management</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/adminuser", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/adminuser">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/Shield-protected.svg"
                )}
              />
            </span>
            <span className="menu-text">Admin Users</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/* User Management
        begin::section
        <li
          className={`menu-item menu-item-submenu  ${getMenuItemActive(
            "/user-management",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/user-management">
            <span className="svg-icon menu-icon">
              <AccountCircleIcon/>
            </span>
            <span className="menu-text">User Managment</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              User Profile
              begin::2 Level
              <li
                className={`menu-item menu-item-submenu ${getMenuItemActive(
                  "/user-management/user-profile",
                  true
                )}`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink
                  className="menu-link menu-toggle"
                  to="/user-management/user-profile"
                >
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">User Profile</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </li> */}
        {/* Components */}

        {/* end:: section */}
        {/* Reports */}
        {/* begin::section */}
        {/* <li className="menu-section ">
          <h4 className="menu-text">Reports</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li> */}
        {/* end:: section */}

        {/* MIS Reports */}
        {/*begin::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
