import axios from "axios";
import store from "./redux/store";
//:Local
const AppAxios = axios.create({
  // baseURL: "http://192.168.2.107:8080/app",
  // baseURL: "http://192.168.0.30:8080/app",
  baseURL: "https://joinaok.org/app",
});

export const Headers = (_id) => {
  const { authToken } = store.getState().auth;
  if (!_id) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: authToken,
    };
  }
  return {
    _id: _id,
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authToken,
  };
};
export default AppAxios;
