import AppAxios from "../../../../axios";

export const LOGIN_URL = "/user/adminlogin";
export const REGISTER_URL = "/user/register";
export const REQUEST_PASSWORD_URL = "/user/ForgetPassword";

export const ME_URL = "/auth/me";
// const user = {
//   id: 1,
//   username: "admin",
//   password: "demo",
//   email: "admin@demo.com",
//   accessToken: "access-token-8f3ae836da744329a6f93bf20594b5cc",
//   refreshToken: "access-token-f8c137a2c98743f48b643e71161d90aa",
//   roles: [1], // Administrator
//   pic: toAbsoluteUrl("/media/users/300_25.jpg"),
//   fullname: "Sean",
//   occupation: "CEO",
//   companyName: "Keenthemes",
//   phone: "456669067890",
//   address: {
//     addressLine: "L-12-20 Vertex, Cybersquare",
//     city: "San Francisco",
//     state: "California",
//     postCode: "45000"
//   },
//   socialNetworks: {
//     linkedIn: "https://linkedin.com/admin",
//     facebook: "https://facebook.com/admin",
//     twitter: "https://twitter.com/admin",
//     instagram: "https://instagram.com/admin"
//   }
// }
export function login(email, password) {
  //return { ...user, password: undefined };
  return AppAxios.post(LOGIN_URL, { email, password });
}

export function register(state) {
  return AppAxios.post(REGISTER_URL, state);
}

export function requestPassword(email) {
  return AppAxios.post(REQUEST_PASSWORD_URL, { email });
}

// export function getUserByToken() {
//   // Authorization head should be fulfilled in interceptor.
//   // return AppAxios.get(ME_URL);
//   return user;
// }
